<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Dashboard" icon="gauge" />

    <div class="dashContainer">
        <div class="row">
            <div class="col-12 mb-2">
                <table width="100%" border="0">
                    <tr>
                        <td width="30%">
                            <!-- <button type="button" class="btn btn-secondary btn-sm mb-1" @click="$router.push({ path: '/auth/preference', query: { ct: 'admin' } })">Admin</button> -->
                            <fa icon="table" class="menu-icon ms-2" style="font-size: 18px"/>
                        </td>
                        <td class="text-end">
                            <span class="d-none d-md-block">
                                <table border="0" align="right">
                                    <tr>
                                        <td class="pe-3">
                                            <span @click="$router.push({ path: '/uploadDoc' })" class="text-primary isLink">
                                                <fa icon="folder-plus" size="lg" class="me-2"/>Add folder
                                            </span>
                                        </td>
                                        <td class="pe-3">
                                            <span @click="$router.push({ path: '/doc/verify' })" class="text-primary isLink">
                                                <fa icon="check-double" size="lg" class="me-2"/>Verify docs
                                            </span>
                                        </td>
                                        <td v-if="isSignOn === true" class="pe-2 bg-warning">
                                            <span @click="$router.push({ path: '/dashboard/report' })" class="text-primary isLink">
                                                <fa icon="file-contract" size="lg" class="me-2"/>Report
                                            </span>
                                        </td>
                                        <td v-if="isSignOn === true" class="bg-warning">
                                            <div class="dropdown dropstart">
                                                <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                                    <fa icon="ellipsis-vertical" class="px-1 text-secondary"/>
                                                </a>
                                                <ul class="dropdown-menu dropdown-menu-secondary">
                                                    <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlInvite">Invite friend</a></li>
                                                    <li><a class="dropdown-item" href="#">Suggestion</a></li>
                                                    <li><span class="dropdown-item isLink" @click="$router.push({ path: '/auth/preference' })">Administration</span></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </span>
                            <span class="d-md-none">
                                <div class="dropdown dropstart">
                                    <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                        <fa icon="ellipsis-vertical" class="px-1 text-secondary"/>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-secondary">
                                        <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlInvite">Invite friend</a></li>
                                        <li><a class="dropdown-item" href="#">Suggestion</a></li>
                                        <li><span class="dropdown-item isLink" @click="$router.push({ path: '/auth/preference', query: { ct: 'admin' } })">Administration</span></li>
                                    </ul>
                                </div>
                            </span>
                        </td>
                    </tr>
                </table>

            </div>
            
        </div>

        <div class="row">
            <!-- Left column -->
            <div class="col-12 col-lg-9">
                <div class="row">
                    <div class="col-12 d-grid">
                        <!-- <div class="border shadow-sm dashBox"> -->

                            <!-- <div class="boxTitle">{{day}}, {{ today }}</div>
           
                            <div class="d-flex flex-wrap justify-content-center" >
                                <span class="filter-pill-border">
                                    <span class="filter-item-pill" @click="filterOverview = 'today'" :class="filterOverview === 'today' ? 'active' : ''">TODAY</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'month'" :class="filterOverview === 'month' ? 'active' : ''">MONTH</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'year'" :class="filterOverview === 'year' ? 'active' : ''">YEAR</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'total'" :class="filterOverview === 'total' ? 'active' : ''">TOTAL</span>
                                </span>
                            </div> -->

                            <div class="row mb-4">
                                <div class="col-6 col-lg-3 mb-3">
                                    <div class="text-center isLink" @click="itemOpt = 'ACTIONREQUIRED'" :class="itemOpt === 'ACTIONREQUIRED' ? 'innerBoxSelected' : 'innerBox'">
                                        <div class="fw-bold" >{{totalAction}}</div>
                                        <div class="small">Action Required</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-3 mb-3">
                                    <div class="text-center isLink" @click="itemOpt = 'WAIT4OTHER'" :class="itemOpt === 'WAIT4OTHER' ? 'innerBoxSelected' : 'innerBox'">
                                        <div class="fw-bold">{{totalWait}}</div>
                                        <div class="small">Waiting for Others</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-3">
                                    <div class="text-center isLink" @click="itemOpt = 'EXPIRING'" :class="itemOpt === 'EXPIRING' ? 'innerBoxSelected' : 'innerBox'">
                                        <div class="fw-bold">{{totalExpiring}}</div>
                                        <div class="small">Expiring Soon</div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-3">
                                    <div class="text-center isLink" @click="itemOpt = 'COMPLETED'" :class="itemOpt === 'COMPLETED' ? 'innerBoxSelected' : 'innerBox'">
                                        <div class="fw-bold">{{totalCompleted}}</div>
                                        <div class="small">Completed</div>
                                    </div>
                                </div>
                            </div>

                        <!-- </div> -->
                    </div>

                    <!-- 
                    <div class="col-lg-4 d-grid">
                        <div class="border shadow-sm dashBox">

                            <div class="boxTitle">{{day}}, {{ today }}</div>
           
                            <div class="d-flex flex-wrap justify-content-center" >
                                <span class="filter-pill-border">
                                    <span class="filter-item-pill" @click="filterOverview = 'today'" :class="filterOverview === 'today' ? 'active' : ''">TODAY</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'month'" :class="filterOverview === 'month' ? 'active' : ''">MONTH</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'year'" :class="filterOverview === 'year' ? 'active' : ''">YEAR</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'total'" :class="filterOverview === 'total' ? 'active' : ''">TOTAL</span>
                                </span>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">{{totalProject}}</div>
                                        <div class="small">Folder</div>
                                    </div>
                                </div>
                                <div class="col px-0">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">{{totalInvited}}</div>
                                        <div class="small">Invited</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">{{totalSigned}}</div>
                                        <div class="small">Signed</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 d-grid">
                        <div class="border shadow-sm dashBox">
                            <div class="boxTitle">WORKFLOW</div>

                            <div class="row mb-2">
                                <div class="col">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">2</div>
                                        <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc pending for me to sign">Pending</Popper></div>
                                    </div>
                                </div>
                                <div class="col px-0">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">50</div>
                                        <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc approved by me">Approved</Popper></div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">2</div>
                                        <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc rejected by me">Rejected</Popper></div>
                                    </div>
                                </div>
                            </div>

                            <div class="innerBox text-center mb-2">
                                <div class="row px-1">
                                    <div class="col text-center">
                                        <div class="fw-bold">65</div>
                                        <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc initiated by me">Initiated</Popper></div>
                                    </div>
                                    <div class="col text-center px-1">
                                        <div style="border: 1px solid grey; border-radius: 10px;">
                                            <div class="fw-bold">5</div>
                                            <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc that initiated by me still in progress">OnGoing</Popper></div>
                                        </div>
                                    </div>
                                    <div class="col text-center px-1">
                                        <div style="border: 1px solid grey; border-radius: 10px">
                                            <div class="fw-bold">60</div>
                                            <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc that initiated by me is completed">Completed</Popper></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- SSO -->    
                    <!-- <div class="col-lg-5 d-grid">
                        
                        <div class="border shadow-sm dashBox">
                            <div class="mb-2">
                                <span class="boxTitle">SSO </span>
                                <span class="float-end small"><router-link to="/dashboard/sso">More</router-link></span>
                            </div>
                            <div class="row">
                                <div class="col-4 text-center mb-3">
                                    <img alt="Vue logo" src="../../assets/signon_logo7.svg" class="align-top" style="height: 20px" />
                                    <fa icon="qrcode" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                    <div class="small text-grey fst-italic">5 secs ago</div>
                                </div>

                                <div class="col-4 text-center mb-3">
                                    <img alt="Vue logo" src="../../assets/logo_notis.png" class="align-top" style="height: 20px" />
                                    <fa icon="key" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                    <div class="small text-grey fst-italic">2 days ago</div>
                                </div>

                                <div class="col-4 text-center mb-3">
                                    <img alt="Vue logo" src="../../assets/logo_online_delivery.png" class="align-top" style="height: 20px" />
                                    <fa icon="qrcode" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                    <div class="small text-grey fst-italic">10 days ago</div>
                                </div>

                                <div class="col-4 text-center mb-3">
                                    <img alt="Vue logo" src="../../assets/logo_online_delivery.png" class="align-top" style="height: 20px" />
                                    <fa icon="qrcode" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                    <div class="small text-grey fst-italic">15 days ago</div>
                                </div>

                                <div class="col-4 text-center mb-3">
                                    <img alt="Vue logo" src="../../assets/signon_logo7.svg" class="align-top" style="height: 20px" />
                                    <fa icon="qrcode" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                    <div class="small text-grey fst-italic">21 secs ago</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- SSO End -->

                    <div class="col-12">
                        <!-- OutStanding -->
                        <div class="border shadow-sm dashBox">
                            <div class="boxTitle">
                                <span v-if="itemOpt === 'ACTIONREQUIRED'">ACTION REQUIRED</span>
                                <span v-else-if="itemOpt === 'WAIT4OTHER'">WAITING FOR OTHERS</span>
                                <span v-else-if="itemOpt === 'EXPIRING'">EXPIRING SOON</span>
                                <span v-else-if="itemOpt === 'COMPLETED'">COMPLETED</span>

                                <!-- <router-link to="/dashboard/outstanding">OUTSTANDING - {{itemOpt}}</router-link>
                                <span class="float-end isLink" @click="showOutFilter = !showOutFilter"><fa icon="filter" size="lg"/></span> -->
                            </div>
                            <div v-if="showOutFilter" class="row">
                                <div class="col-lg-3 mb-2">
                                    <div class="input-group">
                                        <span class="input-group-text"><fa icon="search" /></span>
                                        <input type="text" v-model="filterDoc" class="form-control" placeholder="Folder Name">
                                        <span v-if="filterDoc && filterDoc.length > 0" class="input-group-text clearSearch" @click="delfilterDoc">
                                            <fa icon="xmark" />
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-3 mb-2">
                                    <table>
                                        <tr>
                                            <td class="pe-2">From</td>
                                            <td><Datepicker v-model="dateFr" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-lg-3 mb-2">
                                    <table>
                                        <tr>
                                            <td class="pe-2">To</td>
                                            <td><Datepicker v-model="dateTo" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-lg-3">
                                    <table align="right">
                                        <tr>
                                            <td class="pe-2">Order</td>
                                            <td>
                                                <select class="form-select">
                                                    <option value="Date">Date ASC</option>
                                                    <option value="DateDesc">Date DESC</option>
                                                    <option value="Doc">Document ASC</option>
                                                    <option value="DocDesc">Document DESC</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div> 
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="row">
                                                    <div class="col-lg-10">Folder</div>
                                                    <div class="col-lg-2 text-end">Sender</div>
                                                </div>
                                           </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, itemIdx) in arrItem" :key="item">
                                            <td>
                                                <router-link :to="'/p/' + item.folderId">
                                                    <div class="row">
                                                        <div class="col-lg-8">
                                                            <table v-if="item"  height="35px" border="0">
                                                                <tr>
                                                                    <td class="align-middle">
                                                                        <!-- <ProjTitleSimple :projId="item.folderId" :isFavourite="item.isFavourite" :permission="item.permission" 
                                                                            :projTitle="item.folderName" :totalSignee="item.totalSignee" :totalDoc="item.totalDocument" :status="item.status" :privacy="item.privacy" 
                                                                            :isEdit="false" :key="'title' + itemIdx" /> -->

                                                                        <!-- Show project info instead of call ProjTitleSimple or LogRocket detected as too many recursion error   -->
                                                                        <span v-if="item.folderId" class="me-1">
                                                                            <span v-if="item.isFavourite && item.isFavourite === true">
                                                                                <fa icon="star" size="lg" color="orange" />
                                                                            </span>
                                                                            <span v-else>
                                                                                <fa icon="star" size="lg" class="text-grey" />
                                                                            </span>
                                                                        </span>

                                                                        <ProjStatus :status="item.status" :shred="item.shred" :showDesc="true" class="me-1" />
                                                                        
                                                                        <span class="fw-bold me-1">
                                                                            <span v-if="item.folderName && item.folderName.length > 0">{{ item.folderName }}</span>
                                                                            <span v-else>-</span>
                                                                        </span>

                                                                        <Popper class="popperDark" arrow hover content="Document">
                                                                            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="file" /> {{ item.totalDocument }}</span>
                                                                        </Popper>

                                                                        <Popper class="popperDark" arrow hover content="Signee">
                                                                            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="user" /> {{ item.totalSignee }}</span>
                                                                        </Popper>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                        <div class="col-lg-4">
                                                            <table align="right" height="35px" border="0">
                                                                <tr>
                                                                    <td class="align-middle text-end">
                                                                        <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(item.dateModify, 'text')">
                                                                            <span class="isTooltipsLabel text-grey small"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateModify), new Date()) }}</i></span>
                                                                        </Popper>
                                                                        <div class="isLabel small">{{item.jCreator.displayName}}</div>
                                                                    </td>
                                                                    <td v-if="item.jCreator.jAvatar.userId" width="50px" class="align-middle">
                                                                        <ImgAvatar :isUser="true" :id="item.jCreator.jAvatar.userId" displayName="" :isVerified="item.jCreator.isVerified" :isActive="item.jCreator.status" :key="itemIdx" privilege="OWNER" align="right" width="30" height="30" />
                                                                        <!-- <Avatar :isUser="true" :avatar="item.jCreator.jAvatar.imgBase64" displayName="" :isVerified="item.jCreator.isVerified" :isActive="item.jCreator.status" :key="itemIdx" privilege="OWNER" align="right" width="30" height="30" /> -->
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Outstanding End -->
                        
                        <!-- WIP -->
                        <div v-if="arrWIP && arrWIP.length > 0" class="col-12 border shadow-sm dashBox">
                            <div class="boxTitle">WIP</div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="row">
                                                <div class="col-lg-10">Folder</div>
                                                <div class="col-lg-2 text-end">Sender</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrWIP" :key="item">
                                            <td>
                                                <router-link :to="'/uploadDoc/' + item.folderId">
                                                    <div class="row">
                                                        <div class="col-lg-8">
                                                            <table v-if="item"  height="35px" border="0">
                                                                <tr>
                                                                    <td class="align-middle">
                                                                        <!-- <ProjTitleSimple :projId="item.folderId" :isFavourite="item.isFavourite" :permission="item.permission" 
                                                                            :projTitle="item.folderName" :totalSignee="item.totalSignee" :totalDoc="item.totalDocument" :status="item.status" :privacy="item.privacy" 
                                                                            :isEdit="false" :key="'title' + itemIdx" /> -->

                                                                        <!-- Show project info instead of call ProjTitleSimple or LogRocket detected as too many recursion error   -->
                                                                        <span v-if="item.folderId" class="me-1">
                                                                            <span v-if="item.isFavourite && item.isFavourite === true">
                                                                                <fa icon="star" size="lg" color="orange" />
                                                                            </span>
                                                                            <span v-else>
                                                                                <fa icon="star" size="lg" class="text-grey" />
                                                                            </span>
                                                                        </span>

                                                                        <ProjStatus :status="item.status" :shred="item.shred" :showDesc="true" class="me-1" />
                                                                        
                                                                        <span class="fw-bold me-1">
                                                                            <span v-if="item.folderName && item.folderName.length > 0">{{ item.folderName }}</span>
                                                                            <span v-else>-</span>
                                                                        </span>

                                                                        <Popper class="popperDark" arrow hover content="Document">
                                                                            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="file" /> {{ item.totalDocument }}</span>
                                                                        </Popper>

                                                                        <Popper class="popperDark" arrow hover content="Signee">
                                                                            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="user" /> {{ item.totalSignee }}</span>
                                                                        </Popper>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                        <div class="col-lg-4">
                                                            <table align="right" height="35px" border="0">
                                                                <tr>
                                                                    <td class="align-middle text-end">
                                                                        <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(item.dateModify, 'text')">
                                                                            <span class="isTooltipsLabel text-grey small"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateModify), new Date()) }}</i></span>
                                                                        </Popper>
                                                                        <div class="isLabel small">{{item.jCreator.displayName}}</div>
                                                                    </td>
                                                                    <td width="50px" class="align-middle">
                                                                        <ImgAvatar :isUser="true" :id="item.jCreator.jAvatar.userId" displayName="" :isVerified="item.jCreator.isVerified" :isActive="item.jCreator.status" :key="itemIdx" privilege="OWNER" align="right" width="30" height="30" />
                                                                        <!-- <Avatar :isUser="true" :avatar="item.jCreator.jAvatar.imgBase64" displayName="" :isVerified="item.jCreator.isVerified" :isActive="item.jCreator.status" :key="itemIdx" privilege="OWNER" align="right" width="30" height="30" /> -->
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- WIP End -->
                    </div>

                    
                </div>
            </div>

            <!-- Right column -->
            <div class="col-12 col-lg-3">

                <!-- Entity Invitation -->
                <div v-if="arrEntInvite && arrEntInvite.length > 0" class="border shadow-sm dashBox">
                    <div class="boxTitle">
                        <router-link to="/">ENTITY INVITATION</router-link>
                    </div>

                    <div class="dashBoxContent">
                        <table class="table table-hover" style="margin-bottom: 0px; padding-bottom: 0px;">
                            <tbody>
                                <tr v-for="ent in arrEntInvite" :key="ent">
                                    <td>
                                        <div v-if="ent.status === 'PENDING'" class="isLink" @click="objJoinEntity = ent" data-bs-toggle="modal" data-bs-target="#mdlJoinEntity">
                                            <div v-if="ent.jEntity.jLogo.entityId" class="mb-2">
                                                <ImgLogo :id="ent.jEntity.jLogo.entityId" status="" :isVerified="ent.jEntity.isVerified" :width="ent.jEntity.jLogo.width" :height="ent.jEntity.jLogo.height" />
                                                <!-- <Logo v-if="ent.jEntity.jLogo.imgBase64" status="" :isVerified="ent.jEntity.isVerified" :url="ent.jEntity.jLogo.imgBase64" :width="ent.jEntity.jLogo.width" :height="ent.jEntity.jLogo.height" /> -->
                                            </div>
                                            <div class="fw-bold">{{ent.jEntity.entityName}}</div>
                                            <div><span>Inviter: </span><span class="text-grey">{{ent.createdBy}}</span></div>
                                            <div><span>Expired: </span><span class="text-grey">{{ func.convDateTimeFormat(ent.dateExpiry, 'text') }}</span></div>
                                            <div><span>Invitation code: </span><span class="text-grey">{{ent.invitationCode}}</span></div>
                                            <div>
                                                <span>Status: </span>
                                                <span v-if="ent.status === 'PENDING'" class="text-grey">PENDING</span>
                                                <span v-if="ent.status === 'ACTIVE'" class="text-grey">USED</span>
                                                <span v-if="ent.status === 'EXPIRED'" class="text-grey">EXPIRED</span>
                                            </div>
                                            <div>
                                                <span class="small text-grey fst-italic float-end">{{ func.getDateTimeDiff(func.convDateTimeFormat(ent.dateCreate), new Date()) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div v-if="ent.jEntity.jLogo.entityId" class="mb-2">,,,
                                                <ImgLogo :id="ent.jEntity.jLogo.entityId" status="" :isVerified="ent.jEntity.isVerified" :width="ent.jEntity.jLogo.width" :height="ent.jEntity.jLogo.height" />
                                                <!-- <Logo v-if="ent.jEntity.jLogo.imgBase64" status="" :isVerified="ent.jEntity.isVerified" :url="ent.jEntity.jLogo.imgBase64" :width="ent.jEntity.jLogo.width" :height="ent.jEntity.jLogo.height" /> -->
                                            </div>
                                            <div class="fw-bold">{{ent.jEntity.entityName}}</div>
                                            <div><span>Inviter: </span><span class="text-grey">{{ent.createdBy}}</span></div>
                                            <div><span>Expired: </span><span class="text-grey">{{ func.convDateTimeFormat(ent.dateExpiry, 'text') }}</span></div>
                                            <div><span>Invitation code: </span><span class="text-grey">{{ent.invitationCode}}</span></div>
                                            <div>
                                                <span>Status: </span>
                                                <span v-if="ent.status === 'PENDING'" class="text-grey">PENDING</span>
                                                <span v-if="ent.status === 'ACTIVE'" class="text-grey">USED</span>
                                                <span v-if="ent.status === 'EXPIRED'" class="text-grey">EXPIRED</span>
                                            </div>
                                            <div>
                                                <span class="small text-grey fst-italic float-end">{{ func.getDateTimeDiff(func.convDateTimeFormat(ent.dateCreate), new Date()) }}</span>
                                            </div>
                                        </div>    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Notification -->
                <div class="border shadow-sm dashBox">
                    <div class="boxTitle">
                        <router-link to="/dashboard/notification">NOTIFICATION</router-link>
                        <span class="float-end">
                            <Popper class="popperDark" arrow hover content="Clear all notifications">
                            <fa icon="trash" class="isLink" />
                            </Popper>
                        </span>
                    </div>

                    <div class="dashBoxContent">
                        <table class="table table-hover" style="margin-bottom: 0px; padding-bottom: 0px;">
                            <tbody>
                                <tr v-for="(n, nIdx) in arrNotif" :key="n">
                                    <td>
                                        <div class="fw-bold">
                                            <span v-if="n.isRead"><fa icon="envelope-open" class="me-2" /></span>
                                            <span v-else @click="readNot(n.id, n.isRead)"><fa icon="envelope" class="me-2 isLink" /></span> 

                                            <span v-if="n.action === 'ADD FOLDER'">Folder added</span>
                                            <span v-if="n.action === 'ADD FOLDER SIGNEE'">Signee added</span>
                                            <span v-if="n.action === 'SEND FOLDER'">Invitation sent</span>
                                            <span v-if="n.action === 'FOLDER SEALED'">Folder sealed</span>
                                            <span v-if="n.action === 'REVIEW FOLDER'">Review folder</span>
                                            <span v-if="n.action === 'UPLOAD DOCUMENT'">Document uploaded</span>
                                            <span v-if="n.action === 'UPDATE DOCUMENT'">Document updated</span>
                                            <span v-if="n.action === 'UPDATE FOLDER'">Folder updated</span>
                                            <span v-if="n.action === 'UPDATE FOLDER SIGNEE'">Signee updated</span>
                                            <span v-if="n.action === 'FOLDER VIEWED'">Folder viewed</span>
                                            <span v-if="n.action === 'DOCUMENT SIGNED'">Document signed</span>
                                            <span v-if="n.action === 'DOCUMENT ALLSIGNED'">Document signed by all</span>
                                            <span v-if="n.action === 'DELETE FOLDER SIGNEE'">Signee deleted</span>
                                            <span v-if="n.action === 'DELETE DOCUMENT'">Document deleted</span>

                                            <span class="float-end"><fa icon="xmark" class="isLink" /></span>
                                        </div>
                                        <div>
                                            <span>{{n.description}}</span>
                                        </div>
                                        <div class="small text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat(n.dateCreate), new Date()) }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Notification End -->
            </div>

            <div v-if="isCookieEnable === false" class="col-12 text-grey">
                Cookies disabled. We encourage to enable cookies for better user experience.
            </div>
        </div>
    </div>

    <!-- Modal: Join entity --> 
    <div class="modal fade" id="mdlJoinEntity" tabindex="-1" aria-labelledby="JoinEntity" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Join entity</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="objJoinEntity" class="row">
                        <div class="col-12 mb-2">Confirm to join '{{ objJoinEntity.jEntity.entityName }}' entity?</div>
                        <div class="col-12">Join '{{ objJoinEntity.jEntity.entityName }}' entity will suspended your current entity</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button type="button" class="btn btn-secondary" @click="joinEntity" data-bs-dismiss="modal">
                        <fa icon="user-plus" class="me-2" />Join
                    </button>

                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

    <!-- Modal: Invite friend --> 
    <div class="modal fade" id="mdlInvite" tabindex="-1" aria-labelledby="EditProj" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark modal-sm">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Invite friend</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2"><input type="text" v-model="email" class="form-control" placeholder="Email" @focus="true" /></div>
                        <div class="col-12"><input type="text" v-model="displayName" class="form-control" placeholder="Name" @focus="true" /></div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button type="button" class="btn btn-secondary" @click="inviteFriend" data-bs-dismiss="modal">
                        <fa icon="envelope" class="me-2" />Invite
                    </button>

                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->
    
</template>

<script>
import { ref, inject, onMounted, computed, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
// import TopNavigation from '@/components/TopNavigationMseOver.vue'
import Alert from '@/components/Alert.vue'
import auth from '@/functions/auth'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
// import Logo from '@/components/Logo.vue'
import ImgLogo from '@/components/ImgLogo.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import ProjStatus from '@/components/ProjStatus.vue'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import ProjTitleSimple from '@/components/ProjTitleSimple.vue'

export default {
    name: 'Dashboard',
    components: { Alert, TopNavigation, Popper, ImgAvatar, ImgLogo, Datepicker, ProjStatus, ProjTitleSimple },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const authFunc = auth
        const store = useStore()
        const { getTeam, getStructure } = useStore()

        const isSignOn = ref(false) // detect if from signon Entity

        const email = ref(null)
        const displayName = ref(null)
        const today = ref('')
        const day = ref('')
        const maxOut = ref(10)      // Max oustanding records
        const maxNotif = ref(10)    // Max SSO records
        const itemOpt = ref(null)   // ACTIONREQUIRED, WAIT4OTHER, EXPIRING, COMPLETED

        const showOutFilter = ref(false)
        const filterOverview = ref('today')
        const filterDoc = ref('')
        const dateFr = ref(new Date())
        const dateTo = ref(new Date())

        const arrNotif = ref([])
        const arrNotifRaw = ref([])
        const arrOut = ref([])
        const arrEntInvite = ref([])
        const arrItem = ref([])     // based on action required, waiting for others, expiring soon and completed
        const arrWIP = ref([])

        const totalAction = ref(0)
        const totalWait = ref(0)
        const totalExpiring = ref(0)
        const totalCompleted = ref(0)

        const objEntity = ref(null)
        const objTeam = ref(null)
        const objPermission = ref(null)
        const objJoinEntity = ref(null)

        const isCookieEnable = ref(null) // chk if cookie / web storage enable

        watch([itemOpt], () => {
            getItemList()

        })

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const inviteFriend = () => {
            const p = {
                email: email.value,
                displayName: displayName.value,
                frontendUrl: "/auth/signup"
            }

            axios.post( '/auth/invite/signUp', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Invitation is sent."
                        })

                    } else {
                        func.addLog('dashboardOverview', 'inviteFriend', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('dashOverview', 'inviteFriend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const joinEntity = () => {
            axios.get( '/user/entity/join/' + objJoinEntity.value.invitationCode)
                .then((res) => {

                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "You have joined '" + objJoinEntity.value.jEntity.entityName + "' entity successfully. Please relogin to relfect the changes."
                        })

                        authFunc.logout()

                        router.push({ path: '/auth/login' })
                        .then(() => {
                            router.go() // refresh the page so that can capture sessionId in store
                        })

                    } else {
                        func.addLog('dashboardOverview', 'joinEntity', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'is_member') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'You already joined this entity.'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                        
                    }

                })
                .catch((error) => {
                    func.addLog('dashOverview', 'joinEntity - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getEntityInvitationList = () => {
           
            axios.get( '/user/entity/list/invitation')
                .then((res) => {
                    if (res.data.status === 1) {
                        arrEntInvite.value = res.data.data

                    } else {
                        func.addLog('dashboardOverview', 'getEntityInvitationList', res)

                        if (res && res.data !== null && res.data !== undefined) {

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                                
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'getEntityInvitationList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getNotification = () => {
            const p = {
                module: ['SIGNON'],
                orderBy: [
                    {
                        field: 'dateCreate',
                        order: 'desc'
                    }
                ],
                limit: "0, " + maxNotif.value
            }

           
            axios.post( '/report/notice', p)
                .then((res) => {
                   
                    if (res.data.status === 1) {
                        const tmpArrNotif = res.data.data

                        tmpArrNotif.forEach((item, idx) => {
                            if(item.action !== 'UPDATE FOLDER' && item.action !== 'UPDATE DOCUMENT' && item.action !== 'VIEW FOLDER' && item.action !== 'VIEW DOCUMENT') {
                                arrNotif.value.push(item)

                            }

                        })

                    } else {
                        func.addLog('dashboardOverview', 'getNotification', res)

                        if (res && res.data !== null && res.data !== undefined) {

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                                
                        }

                    }

                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'getNotification - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getOutstanding = () => {
            /* const p = { 
                orderBy: [
                    {
                        field: "dateModify",
                        order: 'desc'
                    }
                ],
                limit: "0, " + maxOut.value
             } */

            axios.post( '/signon/signee/list', {})
                .then((res) => {
                    if (res.data.status === 1) {
                        arrOut.value = res.data.data

                    } else {
                        func.addLog('dashboardOverview', 'getOutstanding', res)

                        if (res && res.data !== null && res.data !== undefined) {

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                                
                        }

                    }

                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'getOutstanding - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const delfilterDoc = () => {
            arrOut.value = []
            filterDoc.value = ''
            getOutstanding()
        }

        const getStatistic = () => {
            axios.get( '/report/dash/signon/WAIT4OTHER')
                .then((res) => {
                    if (res.data.status === 1) {
                        totalWait.value = res.data.data.length

                    } else {
                        func.addLog('dashboardOverview', 'getStatistic WAIT4OTHER', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'internal_error') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "Failed to load Waiting for Others. (" + res.data.status + ":" + res.data.message + ")"
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                                
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'getStatistic WAIT4OTHER - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
                })

            axios.get( '/report/dash/signon/EXPIRING')
                .then((res) => {
                    if (res.data.status === 1) {
                        totalExpiring.value = res.data.data.length

                    } else {
                        func.addLog('dashboardOverview', 'getStatistic EXPIRING', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'internal_error') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "Failed to load Expiring Soon. (" + res.data.status + ":" + res.data.message + ")"
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                                
                        }

                    }

                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'getStatistic EXPIRING - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
                })
            
            axios.get( '/report/dash/signon/COMPLETED')
                .then((res) => {
                    if (res.data.status === 1) {
                        totalCompleted.value = res.data.data.length

                    } else {
                        func.addLog('dashboardOverview', 'getStatistic COMPLETED', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'internal_error') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: "Failed to load Completed. (" + res.data.status + ":" + res.data.message + ")"
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                                
                        }

                    }
                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'getStatistic COMPLETED - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const getWIPList = async () => {
            const p = {
                "status": "WIP"
            }

            await axios.post('/signon/list', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        arrWIP.value = res.data.data
        
                    } else {
                        func.addLog('dashboardOverview', 'getWIPList', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }

                    }
                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'getWIPList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getItemList = () => {
            axios.get( '/report/dash/signon/' + itemOpt.value)
                .then((res) => {

                    if (res.data.status === 1) {
                        arrItem.value = res.data.data

                        if (itemOpt.value === 'ACTIONREQUIRED') {
                            totalAction.value = res.data.data.length

                        } else if (itemOpt.value === 'WAIT4OTHER') {
                            totalWait.value = res.data.data.length

                        } else if (itemOpt.value === 'EXPIRING') {
                            totalExpiring.value = res.data.data.length

                        } else if (itemOpt.value === 'COMPLETED') {
                            totalCompleted.value = res.data.data.length

                        }

                    }  else {
                        func.addLog('dashboardOverview', 'getItemList ' + itemOpt.value, res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'internal_error') {
                                if (itemOpt.value === 'ACTIONREQUIRED') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: "Failed to load Action Required. (" + res.data.status + ":" + res.data.message + ")"
                                    })

                                } else if (itemOpt.value === 'WAIT4OTHER') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: "Failed to load Waiting for Others. (" + res.data.status + ":" + res.data.message + ")"
                                    })

                                } else if (itemOpt.value === 'EXPIRING') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: "Failed to load Expiring Soon. (" + res.data.status + ":" + res.data.message + ")"
                                    })
                                } else if (itemOpt.value === 'COMPLETED') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: "Failed to load Completed. (" + res.data.status + ":" + res.data.message + ")"
                                    })
                                }

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('dashboardOverview', 'getItemList - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const getToday = () => {
            let date = new Date()
            let d = date.getDate() + ''

            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            
            today.value = d + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
            day.value = days[date.getDay()]
           
        }

        const readNot = (id, isRead) => {
            if (isRead === false) {
                const p = { id: [ id ] }

                axios.put( '/report/notice/read', p
                    ).then((res) => {

                        if (res.data.status === 1) {
                            getNotification()

                        } else {
                            func.addLog('dashboardOverview', 'readNot', res)

                            if (res && res.data !== null && res.data !== undefined) {

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                                    
                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('dashboardOverview', 'readNot - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            }

        }

        onMounted(() => {
            document.body.style.overflow = 'scroll'
            
            func.clearPrevLocalStorage()
            isCookieEnable.value = (navigator.cookieEnabled === true ? true : false)
            
            objTeam.value = func.decodeStrToJson(localStorage.getItem('team'))
            objPermission.value = func.decodeStrToJson(localStorage.getItem('permission'))
            objEntity.value = func.decodeStrToJsonSimple(localStorage.getItem('entity'))

            if ( objEntity.value.entityId === '1') {
                isSignOn.value = true

            }

            /* getToday()

            let date = new Date()
            let monthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
            let yearLastDay = new Date(date.getFullYear(), 12, 0).getDate()
            // console.info('date', date)

            todayFr.value = func.convDateTimeFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()), 'date') + ' 00:00:00'
            todayTo.value = func.convDateTimeFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate()), 'date') + ' 23:59:59'

            monthFr.value = new Date(date.getFullYear(), date.getMonth(), 1)
            monthTo.value = new Date(date.getFullYear(), date.getMonth(), monthLastDay)
            
            monthFr.value = func.convDateTimeFormat(monthFr.value, 'date') + ' 00:00:00'
            monthTo.value = func.convDateTimeFormat(monthTo.value, 'date') + ' 23:59:59'

            yearFr.value = new Date(date.getFullYear(), 0, 1)
            yearTo.value = new Date(date.getFullYear(), 11, yearLastDay)

            yearFr.value = func.convDateTimeFormat(yearFr.value, 'date') + ' 00:00:00'
            yearTo.value = func.convDateTimeFormat(yearTo.value, 'date') + ' 23:59:59'

            getTotal(todayFr.value, todayTo.value) */
            
            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })

            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back " + store.getDisplayName.value + ", your last login was " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }

            }

            itemOpt.value = 'ACTIONREQUIRED'

            getNotification()
            getStatistic()
            getEntityInvitationList()
            getWIPList()

        })

        return { 
            alert, closeAlert, router, func, store, 
            today, day, maxNotif, maxOut, arrNotif, arrOut, arrItem, arrWIP, filterDoc, delfilterDoc, 
            dateFr, dateTo, showOutFilter, filterOverview, 
            totalAction, totalWait, totalExpiring, totalCompleted,  
            inviteFriend, getTeam, getStructure,
            objTeam, objPermission, objEntity, isSignOn, readNot, itemOpt, isCookieEnable, email, displayName, 
            getItemList, arrEntInvite, joinEntity, objJoinEntity
        }
    }
}
</script>

<style>

.dashContainer {
  width: 98%; 
  margin: 105px auto 70px auto;
}

.dashBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
}

.dashBoxContent {
    height: 100%; 
    overflow: auto;
    padding-right: 5px;
}

.innerBox {
    border-radius: 10px;
    padding: 10px;
    background-color: var(--auth-box-bg);
}

.innerBox:hover, .innerBoxSelected, .innerBoxSelected:hover {
    border-radius: 10px;
    padding: 10px;
    background-color: var(--bs-primary);
    color: white;
}

@media (max-width: 500px) {
    .dashContainer {
      width: 95%;
      margin: 105px auto 20px auto;
    }

    .dashBox {
      border-radius: 10px;
    }
}

.boxTitle {
    font-size: 12px;
    font-weight: 700 !important;
    margin-bottom: 0.5rem !important;
    box-sizing: border-box;
    color: #6E777E;
    line-height: var(--bs-body-line-height);
}

.dp__input {
    font-size: 14px;
}
</style>